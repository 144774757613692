import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Transaction } from '../models/transaction';
import { Observable, of } from 'rxjs';
import { GlobalAppData } from '../models/global-app-data';
import { getQueryParamByName } from '../common/utils';
import { environment } from '../../environments/environment';
import { delay, tap, flatMap } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import {TransactionStatus} from '../models/transaction-status';

import {BrowserDetails} from '../models/browser-details';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient,
              private gd: GlobalAppData,
              private loadingService: LoadingService) { }
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getOrder(orderId?: string): Observable<Transaction> {
    if (!orderId) {
      orderId = getQueryParamByName('t');
    }
    this.gd.transId = orderId;
    const url = environment.PAYMENT_UI + '/epms/order/' + orderId;
    return this.http.get<Transaction>(url, this.httpOptions);
  }

  fetchPgResponse(): Observable<string> {
    const orderId = this.gd.transId;
    const url = environment.PAYMENT_UI + '/epms/order/final/' + orderId;
    return of(url).pipe(tap((data) => this.loadingService.setLoading(true, data)),
      delay(2000), flatMap(data => this.http.get<string>(url)));
  }

  updateTransactionStatus(transactionStatus: TransactionStatus): Observable<string> {
    const url = environment.PAYMENT_UI + '/epms/updateTxStatus';
    return this.http.post<string>(url, transactionStatus, this.httpOptions);

  }

  updateCustomerBrowserDetails(browserDetails: BrowserDetails): Observable<string> {
    const url = environment.PAYMENT_UI + '/epms/customerBrowserDetails';
    return this.http.post<string>(url, browserDetails, this.httpOptions);

  }

  sendTxRejectStatus(transactionId:string): void {
    const orderTx = this.gd.orderTransaction;
    orderTx.orderTransactionId = transactionId != null ? transactionId : orderTx.orderTransactionId
    const transactionStatus = new TransactionStatus();
    transactionStatus.orderTransactionId = orderTx.orderTransactionId;
    transactionStatus.pgmResponseCode = '203';
    transactionStatus.pgmResponseMessage = 'The transaction has been cancelled by the user.';
    const obs = this.updateTransactionStatus(transactionStatus);

    obs.subscribe({next:data => {
      console.log(' tx reject status updated succefully ');
    }, error:error => {
      console.log('error while updating the tx status');
    }});
  }


}
